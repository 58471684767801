import Slider from '../../../views/pc/slider';
import Banner from '../../../views/pc/banner';

import './style.css';
import { useState, useRef } from 'react';

function MainPage() {

  const [headerAlpha, setHeaderAlpha] = useState(0);
  const SliderRef = useRef(null);

  const handleOnScroll = (offset) => {
    // console.log('xxxx', offset);
    setHeaderAlpha(offset/window.innerHeight)
  }

  const handleLogoPress = () => {
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth' // 可选，平滑滚动效果
    // });
    console.log('xxx scrolltotop', SliderRef.current)
    SliderRef.current.childMethod();
    // window.scrollTop = 0;
    // window.scrollTo(0, 0);
  }

  const headerColor = 'rgba(255, 255, 255, ' + headerAlpha +')';
  // console.log('yyy', headerColor);

  return (
    <div className="mainpage"  >
      <div className='header' style={{backgroundColor: headerColor }} >
        <img className='logo' src='https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/logo.png'  onClick={handleLogoPress}></img>
        
      </div>
        <Slider onScrollOffset={handleOnScroll} ref={SliderRef}/>
    </div>
  );
}

export default MainPage;
