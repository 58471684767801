import React, { useState, useRef, useImperativeHandle } from 'react';
import './style.css';
import Banner from '../banner'
import ViewHome from '../viewHome';
import ViewService from '../viewService';
import ViewTeam from '../viewTeam';
import ViewContact from '../viewContact';
import ViewCompany from '../viewCompany';

function Slider({onScrollOffset}, ref) {
  // const [currentScreen, setCurrentScreen] = useState(0);
  const sliderRef = useRef(null);

  const handleScroll = (event) => {
    // 计算滚动偏移量
    const scrollOffset = event.target.scrollTop;
    //
    onScrollOffset(scrollOffset)
    // 计算屏幕索引
    // const screenIndex = Math.floor(scrollOffset / window.innerHeight);
    // setCurrentScreen(screenIndex);
  };

  const handleSrollToTop = () => {
    console.log('xxxxx', sliderRef.current.scrollTop); sliderRef?.current.scrollTo(0, 0)
  }

  // 使用 useImperativeHandle 定义开放的函数
  useImperativeHandle(ref, () => ({
    childMethod: handleSrollToTop
  }));

  return (
    <div className="slider" ref={sliderRef} onScroll={handleScroll}>
      <div className="screen1">
        <ViewHome />
      </div>
      <div className="screen4">
        <ViewCompany />
      </div>
      <div className="screen2">
        <ViewService />
      </div>
      <div className="screen2">
        <ViewTeam />
      </div>
      <div className="screen3">
        <ViewContact />
      </div>


      {/* 添加更多屏幕 */}
    </div>
  );
}

const ForwardSlider = React.forwardRef(Slider);


export default ForwardSlider;