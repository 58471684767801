import React, { useState } from 'react';
import './style.css';

function ViewTeam() {

  return (
    <div className="viewteam">
      <div className='title'>团队介绍</div>
      <img  className='imageLine' src='https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/headerline.png' />
      <div className='textcontent'><div className='line1'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;心西游季的心理专家团队作为国内第一批且在一线的心理工作者，</div>
      <div className='line2'>对亲子家庭、青少年、职场和女性等群体均有超过10年的临床和</div>
      <div className='line3'>实战经验，对上述人群特点和需求以及对中国心理行业的认识透彻</div>
      和深入。

      <br/><div className='line4'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;同时，心西游季创始团队具有丰富的教育行业和科技行业的落地实践经验和创新研发能力。</div>
      </div>
    </div>
  );
}

export default ViewTeam;