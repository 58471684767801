import React, { useState } from 'react';
import './style.css';

function ViewContact() {

  return (
    <div className="viewcontact">
      <div className='title'>联系我们</div>
      <img className='imageLine' src='https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/headerline.png' />
      <div className='viewcontent'>
        <div className='textcontent'>010-57223668
          <div className='line2'>marketing@mindtrip.com</div>
          <div className='line3'>北京市海淀区远大路20号</div>
        </div>
      </div>

      <div className='bottom'>
        <div className='bottomtext'>
          Copyright © 2020-2023 MindTrip. 保留所有权利
        </div>
        <div className='bottomtext text2' onClick={() => {window.location.href='https://beian.miit.gov.cn/'}}>
          京B-20202672｜京ICP备20022875号-2
        </div>
        <div className='bottomtext' onClick={() => {window.location.href='https://beian.miit.gov.cn/'}}>
          <div className='bottomtext2'>
            <img className='guohui' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/guohui.png" alt="描述图片的文字" />
            <p>京公网安备 11010802037722号</p>
            </div>
        </div>
      </div>
    </div>
  );
}

export default ViewContact;