import './style.css';
import { useState } from 'react';

function MobileMainPage() {

  const [headerAlpha, setHeaderAlpha] = useState(0.85);

  // const handleOnScroll = (event) => {
  //   console.log('xxxx', event.target.scrollTop);
  //   const scrollOffset = event.target.scrollTop;
  //   setHeaderAlpha(scrollOffset / document.documentElement.clientHeight)
  // }

  const handleScroll = (event) => {
    // 计算滚动偏移量
    const scrollOffset = event.target.scrollTop;
    console.log('xxxx', scrollOffset);

    //
    // 计算屏幕索引
    // const screenIndex = Math.floor(scrollOffset / window.innerHeight);
    // setCurrentScreen(screenIndex);
  };

  const handleScrolltoTop = () => {
    window.scrollTo(0, 0);
  }

  const headerColor = 'rgba(255, 255, 255, ' + headerAlpha + ')';
  console.log('yyy', headerColor);


  const imgsHeight = document.documentElement.clientWidth * 3212 / 1500;

  const imgs = [
    'https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/mobile1.png',
    'https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/mobile2.png',
    'https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/mobile3.png',
    'https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/mobile4.png',
    'https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/mobile5.png',
    'https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/mobile6.png',
  ];



  return (
    <div className="mobilemainpage">
      <div className="divImageBack">
        {imgs.map((item, index) => (
          <div key={index} className="backimgonediv" style={{ height: imgsHeight }}>
            <img src={item} className="backimgone" style={{ height: imgsHeight }} />
          </div>
        ))}
      </div>
      <div className='header' style={{ backgroundColor: headerColor }} onClick={handleScrolltoTop}>
        <img className='logo' src='https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/logo.png'></img>
        <img className='logoout' src='https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/logo.png'></img>
      </div>

      <div className='content' onScroll={handleScroll}>
        <div className='text1 margintop200'>
          北京
          <span className='textHeaderBlue'>心西游季</span>
        </div>
        <div className='text1'>教育科技有限公司</div>
        <div className='text2'>Beijing Mindtrip<br />
          Education Technology Limited </div>
        <div className='viewmid'>
          <div className='textbottom'>心理教育的创新探索者</div>
        </div>
        <div className='text3'>
          公司简介
        </div>
        <div className='text4 text41'>&nbsp;&nbsp;&nbsp;&nbsp;北京心西游季教育科技有限公司</div>
        <div className='text4 text42'>(以下简称"心西游季")是一家致力于</div>
        <div className='text4 text43'>结合脑科学、人工智能和互联网科技，</div>
        <div className='text4 text44'>为儿童青少年群体和家庭教育行业</div>
        <div className='text4 text45'>提供创新心理教育服务的专业机构。</div>
        <div className='text3 text31'>
          公司理念
        </div>
        <div className='text4 text51'>&nbsp;&nbsp;&nbsp;&nbsp;<span className='textHeaderBlue'>心西游季</span>以提升全民心理健康素质</div>
        <div className='text4 text52'>水平为己任，以促进人们心智模式</div>
        <div className='text4 text53'>升级为愿景，传播心理文化，推广</div>
        <div className='text4 text54'>心理教练技能，让"互联网+创新心理</div>
        <div className='text4 text55'>服务"真正融入人们生活。</div>
        <div className='text3 text32'>
          价值观
        </div>
        <div className='view6'>
          <div className='text6'>
            悟勤天道
          </div>
        </div>
        <div className='view6'>
          <div className='text6'>
            知行合一
          </div>
        </div>
        <div className='view6'>
          <div className='text6'>
            心致卓越
          </div>
        </div>
        <div className='view6'>
          <div className='text6'>
            开放创新
          </div>
        </div>
        <div className='text3 text33'>
          产品与服务
        </div>
        <div className='viewproduct'>
          <div className='text7 text71'>儿童教育</div>
          <div className='text7 text72'>家庭教育</div>
          <div className='text7 text73'>心理咨询</div>
          <div className='text7 text74'>EAP服务</div>
        </div>
        <div className='text80'>
          服务的企事业单位包括：
        </div>
        <div className='text8 text81'>中国移动、国家电网、中核集团、胜利</div>
        <div className='text8 text82'>油田、中国银行间协会、国家知识产权局、</div>
        <div className='text8 text83'>中国农业科学院、清华大学总裁班、</div>
        <div className='text8 text84'>中关村科技企业家协会等。</div>
        <div className='text3 text34'>
          团队介绍
        </div>
        <div className='text9 text91'>
          &nbsp;&nbsp;&nbsp;&nbsp;心西游季的心理专家团队作为国内
        </div>
        <div className='text9 text92'>
          第一批且在一线的心理工作者，对亲子<br />
          家庭、青少年、职场和女性等群体均有
        </div>
        <div className='text9 text93'>超过10年的临床和实战经验，对上述
        </div>
        <div className='text9 text94'>人群特点和需求以及对中国心理行业的
        </div>
        <div className='text9 text95'>
          认识透彻和深入。
        </div>
        <div className='text9 text96'>&nbsp;&nbsp;&nbsp;&nbsp;同时，心西游季创始团队具有丰富</div>
        <div className='text9 text97'>的教育行业和科技行业的落地实践经验</div>
        <div className='text9 text98'>和创新研发能力。</div>
        <div className='text3 text35'>
          联系我们
        </div>
        <div className='text10 text101'></div>
        <div className='text10 text102'></div>
        <div className='text10 text103'></div>
        <div className='text10 text201'>010-57223668</div>
        <div className='text10 text202'>marketing@mindtrip.com</div>
        <div className='text10 text203'>北京市海淀区远大路20号</div>
        <div className='text11'>心西游季</div>
        <div className='text12'>微信公众号</div>
      </div>
      <div className='viewbottom'>
        <div className='textbottom'>Copyright © 2020-2023 MindTrip. 保留所有权利</div>
        <div className='textbottom textbottom1'  onClick={() => {window.location.href='https://beian.miit.gov.cn/'}}>京B-20202672｜京ICP备20022875号-2</div>
        <div className='textbottom' onClick={() => { window.location.href = 'https://beian.miit.gov.cn/' }}>
          <div className='bottomtext2'><img className='guohui' src="https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/guohui.png" alt="描述图片的文字" /><div>京公网安备 11010802037722号</div></div>
        </div>
      </div>
    </div>
  );
}

export default MobileMainPage;
