import React, { useState } from 'react';
import './style.css';

function ViewService() {

  return (
    <div className="viewservice">
            <div className='content'></div>

      <div className='title'>产品与服务</div>
      <img  className='imageLine' src='https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/headerline.png' />
      {/* <div className='textTtitle text1'>儿童教育</div>
      <div className='textTtitle text2'>家庭教育</div>
      <div className='textTtitle text3'>心理咨询</div>
      <div className='textTtitle text4'>EAP服务</div>
      <div className='textBottomTop'>服务的企事业单位包括：</div>
      <div className='textBottomContent'>中国移动、国家电网、中核集团、胜利油田、中国银行间协会、国家知识产权局、<br/>
中国农业科学院、清华大学总裁班、中关村科技企业家协会等。</div> */}
    </div>
  );
}

export default ViewService;