import React, { useState } from 'react';
import './style.css';

function ViewHome() {

  return (
    <div className="viewhome">
      <div className='textHeader'>
        北京
        <span className='textBlue'>心西游季</span>
        教育科技有限公司
      </div>
      <div className='textHeaderEN'>
        Beijing Mindtrip
        <br />
        Education Technology Limited
      </div>
      <div className='viewbottom'>
        <div className='textbottom'>心理教育的创新探索者</div>
      </div>
    </div>
  );
}

export default ViewHome;