import React, {useState} from 'react';
import MainPage from './pages/pc/mainpage';
import MobileMainPage from './pages/mobile/mainpage';
import './App.css';

function App() {

  const [isMobile, setIsMobile] = useState(document.documentElement.clientWidth <=750);
  
  return (
    <div className="App">
        {!isMobile ? MainPage() : <MobileMainPage />}
    </div>
  );
}

export default App;
