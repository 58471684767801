import React, { useEffect, useState } from 'react';
import './style.css';

function ViewCompany() {

  const [type, setType] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (type == 2) {
        setType(0)
      } else {
        setType(type + 1)
      }
    }, 3000);
    return () => {
      clearInterval(timer);
    }
  })

  

  const arrayTabs = [
    {
      id: 0,
      name: '简介',
      img: 'https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/icon32.png',
      imgSelect: 'https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/icon31.png',
      title: '公司简介',
      backclass: 'viewcompany',
      centerclass: 'center',
    }, {
      id: 1,
      name: '理念',
      img: 'https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/icon22.png',
      imgSelect: 'https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/icon21.png',
      title: '公司理念',
      backclass: 'viewcompany viewcompany2',
      centerclass: 'center center2',

    }, {
      id: 2,
      name: '价值观',
      img: 'https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/icon12.png',
      imgSelect: 'https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/icon11.png',
      title: '价值观',
      backclass: 'viewcompany viewcompany3',
      centerclass: 'center center3',

    },
  ]

  const handleTabPress = (element) => {
    setType(element.id);
  }

  return (
    <div className={arrayTabs[type].backclass}>
      <div className='title'>{arrayTabs[type].title}</div>
      <img className='imageLine' src='https://focus-training.oss-cn-hangzhou.aliyuncs.com/mindtrip/mainpage/pc/headerline.png' />
      <div className='tabs'>
        {arrayTabs.map((element) => (
          <div className={type == element.id ? 'tab' : 'tab tabblue'} onClick={() => handleTabPress(element)}>
            <img className='icon' src={type == element.id ? element.imgSelect : element.img}></img>
            <p className={type == element.id ? 'tabtextselect' : 'tabtext'}>{element.name}</p>
          </div>
        ))}
      </div>
      <div className={arrayTabs[type].centerclass}>
          {type === 0 && 
            <>
              <div className='text11'>&nbsp;&nbsp;&nbsp;&nbsp;北京心西游季教育科技有限公司（以下简称</div>
              <div className='text12'>"心西游季"）是一家致力于结合脑科学、人工智能</div>
              <div className='text13'>和互联网科技，为儿童青少年群体和家庭教育行业</div>
              <div className='text14'>提供创新心理教育服务的专业机构。</div>
            </>
          }
          {type === 1 && 
            <>
              <div className='text20 text21'>&nbsp;&nbsp;&nbsp;&nbsp;<span className='blue'>心西游季</span>以提升全民心理健康素质水平为己任，以促进</div>
              <div className='text20 text22'>人们心智模式升级为愿景，传播心理文化，推广心理教练技能，</div>
              <div className='text20 text23'>让"互联网+创新心理服务"真正融入人们生活。</div>
            </>
          }
      </div>
      <div className='youxia'></div>
      <div className='dots'>
      {arrayTabs.map((element) => (
          <div className={type == element.id ? 'dotselect' : 'dot'}>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ViewCompany;